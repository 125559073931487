import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSession } from 'src/contexts'
import { StoreAppProps, getStoreAppByTag } from 'src/utilities/storeApps'
import { AppDetails } from './AppDetails'

export const Store = () => {
  const { apptag } = useParams()
  const { session } = useSession()
  const location = useLocation()
  const navigate = useNavigate()

  const [storeApp, setStoreApp] = useState<StoreAppProps | undefined>(undefined)
  const [currentTab, setCurrentTab] = useState<number>(0)

  function handleClickTab(index: number) {
    if (index === 0)
      navigate(
        apptag ? `integrations/${apptag?.toLowerCase()}` : 'integrations'
      )
    if (index === 1) navigate('tools')
    setCurrentTab(index)
  }
  
  useEffect(() => {
    const storeAPPByTag = getStoreAppByTag(apptag)
    setStoreApp(storeAPPByTag)
  }, [apptag])

  const screen = !!storeApp ? (
    <AppDetails
      tag={storeApp.tag}
      appName={storeApp.appName}
      isAdmin={session.perfilWs === 0}
      nivel={storeApp.nivel}
      grupoMidia={storeApp.grupoMidia}
      descricao={storeApp?.descricao}
    />
  ) : (
    <>
      <Helmet>
        <title>Workspace | Aplicativos</title>
      </Helmet>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Tabs
          sx={{ pt: "24px" }}
          index={location.pathname === "/store/integrations" ? 0 : 1}
        >
          <TabList sx={{}}>
            <Tab
              title="Integrações"
              _selected={{ borderColor: "#2C71F0" }}
              onClick={() => handleClickTab(0)}
              sx={{
                "&:focus": {
                  boxShadow: "none",
                },
              }}
            >
              <Text
                fontSize={"14px"}
                fontWeight={"600"}
                color={currentTab === 0 ? "#2C71F0" : "#667085"}
              >
                Integrações
              </Text>
            </Tab>
            <Tab
              title="Ferramentas"
              _selected={{ borderColor: "#2C71F0" }}
              onClick={() => handleClickTab(1)}
              sx={{
                "&:focus": {
                  boxShadow: "none",
                },
              }}
            >
              <Text
                fontSize={"14px"}
                fontWeight={"600"}
                color={currentTab === 1 ? "#2C71F0" : "#667085"}
              >
                Ferramentas
              </Text>
            </Tab>
          </TabList>
          <TabPanels zIndex={100}>
            <TabPanel
              id="integrações"
              sx={{ padding: "25px 0px", width: "100%" }}
            >
              <Outlet />
            </TabPanel>
            <TabPanel
              id="ferramentas"
              sx={{ padding: "25px 0px", width: "100%" }}
            >
              <Outlet />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );

  return <>{screen}</>
}

