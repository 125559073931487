import { currency } from "src/utilities/currency";
import { formatting } from "@/utilities/formattingToPattern";
import {
  Flex,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { dataOrcamentoInterface } from "./interface";

type ProductsInterface = Pick<dataOrcamentoInterface, "products">;
export const Products = ({ products }: ProductsInterface) => {
  const handleCalculateDiscount = (
    unitaryValue: number,
    quantity: number,
    totalProducts: number
  ) => {
    const valueTotal = unitaryValue * quantity;

    return valueTotal - totalProducts;
  };

  return (
    <Flex
      maxW={{ xl: "1024px", sm: "full" }}
      w="full"
      mx="auto"
      p={{ "2xl": "0 64px 10px 64px;", sm: "0 24px 10px 24px", base: "0 10px 10px 10px" }}
      mt="20px"
      borderBottom="1px solid #ccc"
    >
      <Flex flexDir="column" w="full">
        <Text
          fontFamily={"inter"}
          fontWeight={"700"}
          fontSize={"20px"}
          color="#606060"
        >
          Produtos
        </Text>
        <Flex w="full" fontFamily={"inter"} mt="10px">
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="#6747b3">Código</Th>
                  <Th color="#6747b3">Produto</Th>
                  <Th color="#6747b3">Quantidade</Th>
                  <Th color="#6747b3">Valor</Th>
                  <Th color="#6747b3">Desconto</Th>
                  <Th color="#6747b3">Total</Th>
                </Tr>
              </Thead>
              <Tbody>
                {products?.map((product, key) => (
                  <Tr key={key}>
                    <Td
                      fontFamily={"inter"}
                      fontWeight={"500"}
                      fontSize={"14px"}
                      color="#333333"
                    >
                      {product.sku}
                    </Td>
                    <Td
                      fontFamily={"inter"}
                      fontWeight={"500"}
                      fontSize={"14px"}
                      color="#333333"
                    >
                      {product.name}
                    </Td>
                    <Td
                      fontFamily={"inter"}
                      fontWeight={"500"}
                      fontSize={"14px"}
                      color="#333333"
                    >
                      {product.quantity}
                    </Td>
                    <Td
                      fontFamily={"inter"}
                      fontWeight={"500"}
                      fontSize={"14px"}
                      color="#333333"
                    >
                      {currency(product.unitaryValue)}
                    </Td>
                    <Td
                      fontFamily={"inter"}
                      fontWeight={"500"}
                      fontSize={"14px"}
                      color="#333333"
                    >
                      {currency(
                        handleCalculateDiscount(
                          product.unitaryValue,
                          product.quantity,
                          product.amount
                        )
                      )}
                    </Td>
                    <Td
                      fontFamily={"inter"}
                      fontWeight={"500"}
                      fontSize={"14px"}
                      color="#333333"
                    >
                      {currency(product.amount)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
    </Flex>
  );
};
