import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
  } from '@chakra-ui/react'
import { ChevronRightIcon } from 'lucide-react'
import { Key } from 'react'
import { Link } from 'react-router-dom'
import { useGetUrl } from '../../hooks/useGetUrl'
  
  export const BreadCrumb = () => {
    const { objApps } = useGetUrl()
  
    return (
      <>
        <Box>
          <Breadcrumb
            color="#666666"
            separator={<ChevronRightIcon color="#666666" height={'16px'} />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/initial">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Text fontSize="12px" fontFamily={'Inter'} fontWeight={400}>
                    {' '}
                    Início{' '}
                  </Text>
                </Box>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={objApps?.path || '#'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Text fontSize="12px" fontFamily={'Inter'} fontWeight={400}>
                    {' '}
                    {objApps?.title || ''}{' '}
                  </Text>
                </Box>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {!!objApps?.breadCrumbs &&
              objApps?.breadCrumbs.map(
                (
                  bread: { route: any; title: any },
                  index: Key | null | undefined
                ) => (
                  <BreadcrumbItem key={index} isLastChild={!bread.route}>
                    <BreadcrumbLink
                      as={Link}
                      to={bread.route || ''}
                      isCurrentPage={!bread.route}
                    >
                      <Text
                        fontSize="12px"
                        fontFamily={'Inter'}
                        fontWeight={400}
                      >
                        {' '}
                        {bread!?.title}{' '}
                      </Text>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                )
              )}
          </Breadcrumb>
        </Box>
      </>
    );


}  