import { Route, Routes, useLocation } from 'react-router-dom'
import { Layout } from 'src/components/Layout'
import { useSession } from 'src/contexts'
import { NotFound } from 'src/screens/404'
import { Analytics } from 'src/screens/Analytics'
import { AnalyticsPRO } from 'src/screens/BI'
import { ClubeContador } from 'src/screens/Clubecontador'
import { CompanySettings } from 'src/screens/Company-settings'
import { Certificate } from 'src/screens/Company-settings/Certificate'
import { Charge } from 'src/screens/Company-settings/Charge'
import { General } from 'src/screens/Company-settings/General'
import { Unique } from 'src/screens/Company-settings/General/unique'
import { Plan } from 'src/screens/Company-settings/Plan'
import { AllFiles } from 'src/screens/Files'
import { ImportadorXLS } from 'src/screens/Importador'
import CorrelationPage from 'src/screens/Importador/containers/Pages/correlationPage'
import CorrelationSaved from 'src/screens/Importador/containers/Pages/correlationSaved'
import PreviewPage from 'src/screens/Importador/containers/Pages/selectHeaderTable'
import SelectCorrelationPage from 'src/screens/Importador/containers/Pages/selectLineToImport'
import { Initial } from 'src/screens/Initial'
import { Login } from 'src/screens/Login'
import { Participants } from 'src/screens/Participants'
import { ParticipantCertificate } from 'src/screens/Participants/Certificate'
import { PDFviewer } from 'src/screens/PDFviewer'
import { Quod } from 'src/screens/Quod'
import { Recovery } from 'src/screens/Recovery'
import { Store } from 'src/screens/Store'
import { Integrations } from 'src/screens/Store/Integrations'
import { Instructions } from 'src/screens/Store/Tools/instructions'
import { MercadoLivreLink } from 'src/screens/Store/Integrations/MercacadoLivreLink'
import { Tools } from 'src/screens/Store/Tools/index'
import { Users } from 'src/screens/Company-settings/Users'
import { CreateUser } from 'src/screens/Company-settings/Users/Create'
import { EditingUser } from 'src/screens/Company-settings/Users/Editing'
import { HasAuth, ProtectedRoutes } from './auth'
import { OrcamentoDigital } from 'src/screens/orcamento-digital'

export const MainRoutes = () => {
  const { session } = useSession();
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<HasAuth />}>
        <Route path="/" element={<Login />} />
        <Route path="/recovery" element={<Recovery />} />
      </Route>

      <Route element={<ProtectedRoutes />}>
        <Route element={<Layout />}>
          <Route path="/initial" element={<Initial />} />

          <Route path="/company-settings" element={<CompanySettings />}>
            <Route path="charge" element={<Charge />} />
            <Route
              path="geral"
              element={session.origin === "Unique" ? <Unique /> : <General />}
            />
            <Route path="plan" element={<Plan />} />
            <Route path="certificate" element={<Certificate />} />
            <Route path="users" element={<Users />} />
            <Route path="users/create" element={<CreateUser />} />
            <Route path="users/editing" element={<EditingUser />} />
          </Route>

          <Route path="/participants">
            <Route index element={<Participants />} />
            <Route path="management" element={<ParticipantCertificate />} />
          </Route>

          <Route path="/importa-facil">
            <Route index element={<ImportadorXLS />} />
            <Route path="selectHeaderTable/:data" element={<PreviewPage />} />
            <Route path="correlation/:path" element={<CorrelationPage />} />
            <Route
              path="correlationSaved/:path"
              element={<CorrelationSaved />}
            />
            <Route
              path="selectpage/:params"
              element={<SelectCorrelationPage />}
            />
          </Route>

          <Route path="analytics" element={<Analytics />} />
          <Route path="analytics-pro" element={<AnalyticsPRO />} />

          <Route path="/quod" element={<Quod />} />

          <Route path="/clube-contador" element={<ClubeContador />} />

          <Route path="/files" element={<AllFiles />}>
            <Route path="relatorios" element={<AllFiles />} />
            <Route path="enviados" element={<AllFiles />} />
            <Route path="impressao" element={<AllFiles />} />
          </Route>

          <Route path="/store" element={<Store />}>
            <Route path="integrations" element={<Integrations />}>
              <Route path="" element={<Integrations />} />
              <Route path=":apptag" element={<Integrations />} />
            </Route>
            <Route path="tools" element={<Tools />} />
            <Route path="instructions" element={<Instructions />} />
            <Route path="mercadolivrelink" element={<MercadoLivreLink />} />
          </Route>
        </Route>
      </Route>

      <Route path="/PDFviewer" element={<PDFviewer />} />
      <Route path="/orcamentodigital/:code" element={<OrcamentoDigital />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
