import { formatting } from "src/utilities/formattingToPattern";
import { Flex, Button, Text, useToast } from "@chakra-ui/react";
import { WhatsappLogo } from "phosphor-react";

type sellerInterface = {
  name: string;
  contact: string;
};

export const DataSeller = ({ name, contact }: sellerInterface) => {
  const toast = useToast();

  const openWhatsapp = () => {
    if (!contact) {
      toast({
        title: "Erro",
        description: "Contato do vendedor não encontrado",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    window.open(`https://wa.me/55${contact}`);
  };

  return (
    <Flex
      maxW={{ xl: "1024px", sm: "full" }}
      w="full"
      mx="auto"
      p={{ "2xl": "0 64px 10px 64px;", sm: "0 24px 10px 24px" , base: "0 10px 10px 10px"} }
      mt="20px"
      borderBottom="1px solid #ccc"
    >
      <Flex flexDir="column" w="full">
        <Text
          fontFamily={"inter"}
          fontWeight={"700"}
          fontSize={"20px"}
          color="#606060"
        >
          Vendedor
        </Text>
        <Flex
          w="full"
          fontFamily={"inter"}
          mt="10px"
          gap="35px"
          flexDir={{ xl: "row", md: "row", sm: "column", base: "column" }}
        >
          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Nome do vendedor
            </Text>
            <Text color="#606060">{name}</Text>
          </Flex>

          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Contato
            </Text>
            <Text color="#606060">
              {formatting({
                value: contact ?? "",
                pattern: "phone",
              })}
            </Text>
          </Flex>
          <Button
            h="50px"
            w="full"
            border="1px solid #6747b3"
            leftIcon={<WhatsappLogo size={20} weight="bold" color="#6747b3" />}
            _hover={{
              bg: "#6747b3",
              color: "#fff",
            }}
            color="#6747b3"
            fontWeight={"600"}
            borderRadius="4px"
            bg="#fff"
            mb="10px"
            onClick={openWhatsapp}
          >
            WhatsApp
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
