import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { User } from "@/screens/Company-settings/Users/hooks";
import {
  GestorProfiles,
  Profile,
  UniqueProfiles,
  WorkspaceProfiles,
} from "../../../../../constants/profiles";
import { useSession } from "../../../../../contexts";
import { apiInstance, getUserByUserId } from "../../../../../services/api";
import { DisableUserButton } from "./components/DisableUserButton";
import {
  CaretDown,
  CheckCircle,
  Circle,
  XCircle,
} from "phosphor-react";
import { ActionButton } from "../ActionButton";

export interface UsuariosAtivosTableProps {
  app: { appTag: string; appName: string };
  users: User[];
  reloadMainPage: () => void;
  openModalUser?: () => void;
}

interface getProfileUserPropsReturn {
  profileItem: Profile[];
  valueSelected: string;
  name: string; 
}

export const UsuariosAtivosTable = ({
  app,
  users,
  reloadMainPage,
  openModalUser
}: UsuariosAtivosTableProps) => {
  const toast = useToast();
  const { session } = useSession();
  const [appTag, setAppTag] = useState<string>("");
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const validTags = ["GE_L1", "GE_L2", "GE_L3", "GE_L4"];


  const appsWithPermissions = [
    "SIMPLAZ",
    "GE_L4",
    "GE_L3",
    "GE_L2",
    "GE_L1",
    "GE_N4",
    "GE_N3",
    "GE_N2",
    "GE_N1",
    "UN_L1",
    "UN_L2",
    "UN_L3",
    "UN_N1",
    "UN_N2",
    "UN_N3",
    "SP_P2",
    "SP_P3",
    "SP_P4",
  ];

  const normalizeAppName = () => {
    if (
      app.appTag.toUpperCase().includes("SIMPLAZ") ||
      app.appTag.toUpperCase().includes("SP_")
    ) {
      setAppTag("SIMPLAZ");
    }

    if (app.appTag.toUpperCase().includes("GE_")) {
      setAppTag("GESTOR");
    }

    if (app.appTag.toUpperCase().includes("UN_")) {
      setAppTag("UNIQUE");
    }
  };

  const containProfileApps = (profileApps: string): boolean => {
    if (profileApps === "") return false;
    if (profileApps === undefined) return false;
    if (profileApps === null) return false;
    if (typeof profileApps !== "string") return false;
    if (profileApps.length < 8) return false;

    return true;
  };

  const getProfileUser = (profileApps: string): getProfileUserPropsReturn => {
    // pegar o perfil do usuário para o app
    if (containProfileApps(profileApps)) {
      const appsJson = JSON.parse(profileApps);

      // se o app for o simplaz, pegar o perfil do workspace
      if (
        appsJson.some((app: any) => app.Aplicativo === appTag) &&
        appTag === "SIMPLAZ"
      ) {
        const simplazProfile = appsJson.find(
          (app: any) => app.Aplicativo === "SIMPLAZ"
        ).Perfil;

        const nameProfile = WorkspaceProfiles.find(
          (app) => app.id === simplazProfile
        ) as Profile;
        return {
          profileItem: WorkspaceProfiles,
          valueSelected: nameProfile?.id,
          name: nameProfile.name.replace("Usuário", "Operacional"),
        };
      }

      // se o app for o gestor, pegar o perfil do gestor
      if (
        appsJson.some((app: any) => app.Aplicativo === appTag) &&
        appTag === "GESTOR"
      ) {
        const gestorProfile = appsJson.find(
          (app: any) => app.Aplicativo === "GESTOR"
        ).Perfil;

        const nameProfile = GestorProfiles.find(
          (app) => app.id === gestorProfile
        ) as Profile;
        return {
          profileItem: GestorProfiles,
          valueSelected: nameProfile?.id || "999",
          name: nameProfile?.name || "Selecionar",
        };
      }

      // se o app for o unique, pegar o perfil do unique
      if (
        appsJson.some((app: any) => app.Aplicativo === appTag) &&
        appTag === "UNIQUE"
      ) {
        const uniqueProfile = appsJson.find(
          (app: any) => app.Aplicativo === "UNIQUE"
        ).Perfil;

        const nameProfile = UniqueProfiles.find(
          (app) => app.id === uniqueProfile
        ) as Profile;
        return {
          profileItem: UniqueProfiles,
          valueSelected: nameProfile.id,
          name: nameProfile.name,
        };
      }
    } else {
      if (appTag === "SIMPLAZ") {
        return {
          profileItem: WorkspaceProfiles,
          valueSelected: "999",
          name: "Selecionar",
        };
      }
      if (appTag === "UNIQUE") {
        return {
          profileItem: UniqueProfiles,
          valueSelected: "999",
          name: "Selecionar",
        };
      }
      if (appTag === "GESTOR") {
        return {
          profileItem: GestorProfiles,
          valueSelected: "999",
          name: "Selecionar",
        };
      }
    }

    return {
      profileItem: WorkspaceProfiles,
      valueSelected: "999",
      name: "Selecionar",
    };
  };

  const handleProfileApp = (user: User, profileId: string) => {
    if (
      user.perfilAplicativos !== "" &&
      user.perfilAplicativos.length > 8 &&
      user.perfilAplicativos !== undefined
    ) {
      const profileApp = JSON.parse(user.perfilAplicativos);

      if (profileApp.some((app: any) => app.Aplicativo === appTag)) {
        const profileAppFilter = profileApp.filter((app: any) => {
          return app.Aplicativo !== appTag;
        });

        profileAppFilter.push({
          Aplicativo: appTag,
          Perfil: profileId,
        });

        const newProfileApp =
          profileAppFilter.length === 0
            ? [
                {
                  Aplicativo: appTag,
                  Perfil: profileId,
                },
              ]
            : profileAppFilter;

        return newProfileApp;
      }
    } else {
      const newProfileApp = [
        {
          Aplicativo: appTag,
          Perfil: profileId,
        },
      ];

      return newProfileApp;
    }
  };

  const handleChangeProfileUser = async (user: User, profileId: string) => {
    try {
      const { password } = await getUserByUserId(user.userId);

      const changedUser = {
        ...user,
        perfilAplicativos: JSON.stringify(handleProfileApp(user, profileId)),
        companyCNPJCPF: session.company.document,
        password: password,
      };

      await apiInstance(8006).put("UserGestor", changedUser, {
        headers: {
          authorization: "Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb",
          companyId: session.company.id.toString(),
          cnpjcpf: session.company.document,
        },
      });

      toast({
        title: "Alteração realizada",
        status: "success",
        duration: 3500,
        position: "bottom-right",
        isClosable: true,
      });

      reloadMainPage();
    } catch (e) {
      toast({
        title: "Algo deu errado",
        status: "error",
        duration: 3500,
        position: "bottom-right",
        isClosable: true,
      });
    }
  };

  const handleChangeUpdateUser = async (user: User, status: number) => {
    try {
      const { password } = await getUserByUserId(user.userId);

      const changedUser = {
        ...user,
        indUserAtualiza: status,
        companyCNPJCPF: session.company.document,
        password: password,
      };

      await apiInstance(8006).put("UserGestor", changedUser, {
        headers: {
          authorization: "Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb",
          companyId: session.company.id.toString(),
          cnpjcpf: session.company.document,
        },
      });

      toast({
        title: "Alteração realizada",
        status: "success",
        duration: 3500,
        position: "bottom-right",
        isClosable: true,
      });

      reloadMainPage();
    } catch (e) {
      toast({
        title: "Algo deu errado",
        status: "error",
        duration: 3500,
        position: "bottom-right",
        isClosable: true,
      });
    }
  };

  const getButtonUpdate = (value: any) => {
    const button: any = {
      label: "",
      icon: null,
      action: () => {},
    };

    switch (value) {
      case 1:
        button.label = "Sim";
        button.icon = (
          <CheckCircle
            size={22}
            color="#109972"
            style={{ marginRight: "3px" }}
          />
        );
        break;
      case 0:
        button.label = "Não";
        button.icon = (
          <XCircle size={22} color="#AE0707" style={{ marginRight: "3px" }} />
        );
        break;
      default:
        button.label = "Sel..";
        button.icon = (
          <Circle size={22} color="#aeb0b0" style={{ marginRight: "3px" }} />
        );
        break;
    }
    return button;
  };

  const skeleton = () => {
    return (
      <Stack sx={{ padding: '24px' }}>
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
      </Stack>
    );
  };

  useEffect(() => {
    normalizeAppName();
    if (
      (validTags.includes(app.appTag) && app.appName === "Gestor") ||
      app.appName === "Unique"
    ) {
      setHasUpdated(true);
    }
    setTimeout(() => {
      setLoadingData(false)
    },2000)    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  return (
    <Box height="100%">
      <TableContainer
        sx={{
          whiteSpace: 'unset',
          paddingBottom: '14px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <Table variant="simple" size={'xs'}>
          <Thead sx={{ backgroundColor: '#F9FAFC' }}>
            <Tr>
              <Th width={'30%'} sx={{ padding: '12px 24px' }}>
                <Text fontSize={'14px'} fontFamily={'Inter'} color={'#292929'}>
                  {` Usuários ativos (${users.length})`}
                </Text>
              </Th>
              <Th width={'30%'} sx={{ padding: '12px 0' }}>
                <Text fontSize={'14px'} fontFamily={'Inter'} color={'#292929'}>
                  E-mail
                </Text>
              </Th>
              {hasUpdated && (
                <Th width={'10%'} sx={{ padding: '12px 0' }}>
                  <Text
                    fontSize={'14px'}
                    fontFamily={'Inter'}
                    color={'#292929'}
                  >
                    Atualizar
                  </Text>
                </Th>
              )}
              <Th width={'15%'} sx={{ padding: '12px 0' }}>
                <Text fontSize={'14px'} fontFamily={'Inter'} color={'#292929'}>
                  Perfil
                </Text>
              </Th>
              <Th
                sx={{
                  padding: '12px 24px',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Box>
                  <ActionButton
                    labelButton={'Adicionar'}
                    action={() => openModalUser && openModalUser()}
                  />
                </Box>
              </Th>
            </Tr>
          </Thead>

          <Tbody color={'#606060'}>
            {loadingData ? (
              <Tr>
                <Td colSpan={5}>
                  {skeleton()}
                </Td>
              </Tr>
            ) : (
              <>
                {!loadingData && users.map((user) => {
                  return (
                    <Tr key={user.userId}>
                      <Td width={'30%'} align="left" sx={{ pl: '24px' }}>
                        <Text fontSize={'14px'}>{user.name}</Text>
                      </Td>
                      <Td width={'30%'} align="left">
                        <Text fontSize={'14px'}>{user.email}</Text>
                      </Td>
                      {hasUpdated && (
                        <Td width={'10%'} align="left">
                          <Box width={'100px'}>
                            <Menu>
                              <MenuButton
                                as={Button}
                                rightIcon={
                                  <CaretDown
                                    size={13}
                                    color="#214459"
                                    weight="bold"
                                  />
                                }
                                sx={{
                                  color: '#606060',
                                  border: 'none',
                                  backgroundColor: '#fff',
                                  ':focus': {
                                    boxShadow: 'none',
                                    background: 'none',
                                  },
                                  ':hover': {
                                    background: 'none',
                                  },
                                  paddingInlineStart: 'unset',
                                }}
                              >
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {getButtonUpdate(user.indUserAtualiza).icon}
                                  {getButtonUpdate(user.indUserAtualiza).label}
                                </Box>
                              </MenuButton>
                              <MenuList>
                                <MenuItem
                                  onClick={() =>
                                    handleChangeUpdateUser(user, 3)
                                  }
                                  key="999"
                                >
                                  Selecione
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleChangeUpdateUser(user, 1)
                                  }
                                  key="sim"
                                >
                                  Sim{' '}
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleChangeUpdateUser(user, 0)
                                  }
                                  key="nao"
                                >
                                  Não{' '}
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Box>
                        </Td>
                      )}
                      <Td width={'15%'}>
                        {appsWithPermissions.includes(
                          app.appTag.toUpperCase()
                        ) ? (
                          <Menu>
                            <MenuButton
                              as={Button}
                              rightIcon={
                                <CaretDown
                                  size={13}
                                  color="#214459"
                                  weight="bold"
                                />
                              }
                              sx={{
                                width: '200px',
                                color: '#606060',
                                border: 'none',
                                backgroundColor: '#fff',
                                ':focus': {
                                  boxShadow: 'none',
                                  background: 'none',
                                },
                                ':hover': {
                                  background: 'none',
                                },
                                paddingInlineStart: 'unset',
                              }}
                            >
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {getProfileUser(user.perfilAplicativos).name}
                              </Box>
                            </MenuButton>
                            <MenuList>
                              <MenuItem key="999">Selecione</MenuItem>
                              {getProfileUser(
                                user.perfilAplicativos
                              )?.profileItem.map((item) => (
                                <MenuItem
                                  key={item.id}
                                  onClick={() =>
                                    handleChangeProfileUser(user, item.id)
                                  }
                                >
                                  {item.name.replace('Usuário', 'Operacional')}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        ) : (
                          '-'
                        )}
                      </Td>
                      <Td width={'15%'} isNumeric sx={{ pr: '24px' }}>
                        {
                          <DisableUserButton
                            appTag={app.appTag}
                            user={user}
                            reloadMainPage={reloadMainPage}
                          />
                        }
                      </Td>
                    </Tr>
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
