import { apiInstance } from 'src/services/api'
import { useState } from 'react'
import { useSession } from 'src/contexts/sessions'
import { FavoriteObj } from '../types/types'

export interface User {
  Aplicativo: string
  Perfil: string
  Favoritos: boolean
}

export function useUpdateUser() {
  const { session } = useSession()
  const [data, setData] = useState<FavoriteObj>()
  const [isFetching, setIsFetching] = useState(true)

  const updateFavorite = async (newFavorites: any, user: any) => {
    const updatedUser = updateIndFavorito(user, newFavorites)
    try {
      const response = await apiInstance(8006).put('UserGestor', updatedUser, {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session?.company?.id.toString(),
          cnpjcpf: session?.company?.document,
        },
      })
      if (response.status === 201) {
        setData(newFavorites)
      } else {
        console.error('Falha na atualização do favorito', response)
      }
    } catch (error) {
      console.error('Erro na atualização do favorito', error)
    } finally {
      setIsFetching(false)
    }
  }

  function updateIndFavorito(user: any, newValue: FavoriteObj) {
    if (user.hasOwnProperty('indFavorito')) {
      user['indFavorito'] = JSON.stringify(newValue)
      user['tokenRememberPassword'] = ""
      user['password'] = ""
    } else {
      console.error('A chave indFavorito não existe no objeto user')
    }
    return user
  }

  return { favoriteUpdated: data, isFetching, updateFavorite }
}
