import {
  Text,
  Image,
  Box,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import './../css/integrations.css'
import CardApp from '../AppDetails/components/CardApp'
import { useToolsCard } from '../hooks/useToolsCard'
import { SquareArrowOutUpRight } from 'lucide-react';

export const Tools = () => {
  const { toolApps } = useToolsCard()

  const customHeader = (app: string | undefined, url: string | undefined) => (
    <Box className="cardHeader">
      <Image src={url} h={"42px"} alt="workspace_img" />
      <Text fontSize={"14px"} fontWeight={"600"} fontFamily={"Inter"}>
        {app}
      </Text>
    </Box>
  );

  const customBody = (text?: string) => (
    <Text
      fontSize={"14px"}
      fontWeight={"400"}
      fontFamily={"Inter"}
      color={"#475467"}
    >
      {text}
    </Text>
  );

  const customFooter = (url?: string, isDownload?: boolean) => (
    <Box as="a" className="toolsFooterButton" href={url} target={isDownload ? "_self" : "_blank"}>
      <SquareArrowOutUpRight />
      <Text fontSize={"14px"} fontWeight={"600"} sx={{ cursor: "pointer" }}>
        Abrir
      </Text>
    </Box>
  );

  return (
    <Box className="contentIntegrations">
      <Wrap spacing={"24px"} className="cards">
        {toolApps &&
          toolApps.map((tool, index) => (
            <WrapItem key={index}>
              <CardApp
                header={customHeader(tool?.name, tool?.img)}
                body={customBody(tool?.description)}
                footer={customFooter(tool?.link,tool?.isDownload)}
              />
            </WrapItem>
          ))}
      </Wrap>
    </Box>
  );
};
