import { apiInstance } from "../../../services/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSession } from "src/contexts";

export interface DataProps {
  aplicativoId: number;
  codigo: number;
  nome: string;
  descricao: string;
  indStatus: string;
  valor: number;
  tag: string;
  indAtivar: string;
  indLista: string;
}

export function useGetApps() {
  const isMountedRef = useRef(false);
  const { session } = useSession();

  const [data, setData] = useState<DataProps | null>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const getApps = useCallback(async () => {
    const { data } = await apiInstance(9000).get(
      `/workspace/Aplicativo/Grupo/${session.company.document}`,
      {
        headers: {
          GatewayLS: "2e44bb6339e6aacd8faeca8fd4e8694e",
        },
      }
    );
    setData(data.data);
    setIsFetching(false);
  }, [session.company.document]);

  useEffect(() => {
    isMountedRef.current = true;
    setIsFetching(true);
    if (isMountedRef.current) {
      getApps();
    }

    return () => {
      isMountedRef.current = false;
      setData(null);
    };
  }, [getApps]);
  return { dataApps: data, isFetching };
}
