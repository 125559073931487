import { IconsUrlProduct } from 'src/constants/hooks/iconsApps'
import { useSystems } from 'src/utilities/useSystems'
import { useNavigate } from 'react-router-dom'

export interface ButtonItemProps {
  id: string
  label: string
  iconDefault: string
  iconHover: string
  action: () => void
  iconFavorite: string
}

interface ItemsProps {
  product: ButtonItemProps[]
}
/**
 *
 * @returns nao alterar a ordem do objeto
 */
export const ItemsSubmenuProduct = () => {
  const navigate = useNavigate()
  const { openGestor, openUnique, openSimplaz } = useSystems()

  const btns: ItemsProps = {
    product: [
      {
        id: 'gestor',
        label: 'Gestor',
        iconDefault: IconsUrlProduct?.gestor?.default,
        iconHover: IconsUrlProduct?.gestor?.hover,
        action: () => openGestor(),
        iconFavorite: IconsUrlProduct?.gestor?.favorite,
      },
      {
        id: 'unique',
        label: 'Unique',
        iconDefault: IconsUrlProduct?.unique?.default,
        iconHover: IconsUrlProduct?.unique?.hover,
        action: () => openUnique(),
        iconFavorite: IconsUrlProduct?.unique?.favorite,
      },
      {
        id: 'simplaz',
        label: 'Simplaz',
        iconDefault: IconsUrlProduct?.simplaz?.default,
        iconHover: IconsUrlProduct?.simplaz?.hover,
        action: () => openSimplaz(),
        iconFavorite: IconsUrlProduct?.simplaz?.favorite,
      },
      {
        id: 'analytics',
        label: 'Analytics',
        iconDefault: IconsUrlProduct?.analitics?.default,
        iconHover: IconsUrlProduct?.analitics?.hover,
        action: () => navigate('analytics'),
        iconFavorite: IconsUrlProduct?.analitics?.favorite,
      },
      {
        id: 'analyticspro1',
        label: 'Analytics PRO',
        iconDefault: IconsUrlProduct?.analiticsPro?.default,
        iconHover: IconsUrlProduct?.analiticsPro?.hover,
        action: () => navigate('analytics-pro'),
        iconFavorite: IconsUrlProduct?.analiticsPro?.favorite,
      },
      {
        id: 'analyticspro2',
        label: 'Analytics PRO',
        iconDefault: IconsUrlProduct?.analiticsPro?.default,
        iconHover: IconsUrlProduct?.analiticsPro?.hover,
        action: () => navigate('analytics-pro'),
        iconFavorite: IconsUrlProduct?.analiticsPro?.favorite,
      },
      {
        id: 'clubedocontador',
        label: 'Clube do Contador',
        iconDefault: IconsUrlProduct?.clube?.default,
        iconHover: IconsUrlProduct?.clube?.hover,
        action: () => navigate('clube-contador'),
        iconFavorite: IconsUrlProduct?.clube?.favorite,
      },
      {
        id: 'importafacil',
        label: 'Importa Fácil',
        iconDefault: IconsUrlProduct?.importador?.default,
        iconHover: IconsUrlProduct?.importador?.hover,
        action: () => navigate('importa-facil'),
        iconFavorite: IconsUrlProduct?.importador?.favorite,
      },
      {
        id: 'quod',
        label: 'Quod',
        iconDefault: IconsUrlProduct?.quod?.default,
        iconHover: IconsUrlProduct?.quod?.hover,
        action: () => navigate('quod'),
        iconFavorite: IconsUrlProduct?.quod?.favorite,
      },
    ],
  }
  return btns
}
