export interface StoreAppProps {
  tag: string
  appName: string
  nivel: 'user',
  grupoMidia?: string,
  descricao?:string
}

export const appsCadastrados = [
  'SIMPLAZ',
  'GESTOR',
  'UNIQUE',
  'MercadoLivre',
  'INTELVENDAS',
  'INTELADM',
  'ANALYTICSBI',
  'IMPXLS',
  'QUOD',
]

const AnalyticsBIApps: StoreAppProps[] = [
  {
    tag: 'ANALYTICSBI',
    appName: 'Analytics BI',
    nivel: 'user',
    grupoMidia: 'analyticsbi',
    descricao:'Confira as principais métricas de vendas da sua empresa de forma gratuita e rápida!'
  },
  {
    tag: 'INTELVENDAS',
    appName: 'Analytics PRO',
    nivel: 'user',
    grupoMidia: 'analyticspro',
    descricao :  "Gestão orientada por dados: Saiba de forma simples os resultados da sua empresa com base em dados da operação."
  },
  {
    tag: 'MercadoLivre',
    appName: 'Mercado Livre',
    nivel: 'user',
    grupoMidia: 'mercadolivre',
    descricao : 'Integre seu sistema Gestor ao Mercado Livre e realize a importação de pedidos de forma automatizada!'
  },
  {
    tag: 'SIMPLAZ',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',
  },
  {
    tag: 'SP_P2',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',

  },
  {
    tag: 'SP_P3',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',

  },
  {
    tag: 'SP_P4',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',

  },
  {
    tag: 'SP_CTB_P5',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',

  },
  {
    tag: 'SP_CTB_P6',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',

  },
  {
    tag: 'SP_CTB_P7',
    appName: 'Simplaz',
    nivel: 'user',
    grupoMidia: 'simplaz',
    descricao : 'Realize toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e e XML em um só lugar.',

  },
  {
    tag: 'INTELADM',
    appName: 'Analytics PRO - Inteligência Administrativa',
    nivel: 'user',
    grupoMidia: 'analyticsadm',

  },
  {
    tag: 'IMPXLS',
    appName: 'Importa Fácil',
    nivel: 'user',
    grupoMidia: 'importafacil',
    descricao : 'Use planilhas externas para importar pedidos para o sistema Gestor',
  },
  {
    tag: 'QUOD',
    appName: 'Quod',
    nivel: 'user',
    grupoMidia: 'quod',

  },
  {
    tag: 'CL_CTD_P1',
    appName: 'Clube contador',
    nivel: 'user',
    grupoMidia: 'clubecontador',
  },
  {
    tag: 'CL_CTD_P2',
    appName: 'Clube contador',
    nivel: 'user',
    grupoMidia: 'clubecontador',
  },
  {
    tag: 'CL_CTD_P3',
    appName: 'Clube contador',
    nivel: 'user',
    grupoMidia: 'clubecontador',
  },
  {
    tag: 'CL_CTD_P4',
    appName: 'Clube contador',
    nivel: 'user',
    grupoMidia: 'clubecontador',
  },
  {
    tag: 'CL_CTD_P5',
    appName: 'Clube contador',
    nivel: 'user',
    grupoMidia: 'clubecontador',
  },
  {
    tag: 'CL_CTD_P4',
    appName: 'Clube contador',
    nivel: 'user',
    grupoMidia: 'clubecontador',
  },
  {
    tag: 'GE_N1',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',
  },
  {
    tag: 'GE_N2',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'GE_N3',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'GE_N4',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'GE_L1',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'GE_L2',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'GE_L3',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'GE_L4',
    appName: 'Gestor',
    nivel: 'user',
    grupoMidia: 'gestor',
    descricao: 'Simplifique sua gestão empresarial e tenha total controle financeiro e liberdade para acessar seus dados em qualquer lugar',

  },
  {
    tag: 'UN_N1',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',
  },
  {
    tag: 'UN_N2',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',
  },
  {
    tag: 'UN_N3',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',
  },
  {
    tag: 'UN_N4',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',
  },
  {
    tag: 'UN_L1',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',
  },
  {
    tag: 'UN_L2',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',

  },
  {
    tag: 'UN_L3',
    appName: 'Unique',
    nivel: 'user',
    grupoMidia: 'unique',
    descricao: 'Solução completa para contadores que buscam simplificar e aprimorar suas operações contábeis e fiscais.',

  },
  {
    tag: 'MOBVD',
    appName: 'Mobile de vendas',
    nivel: 'user',
    grupoMidia: 'mbv',
    descricao: 'Otimize seu processo de vendas e melhore sua eficiência operacional diretamente do seu dispositivo móvel.',
  },
  {
    tag: 'BIME',
    appName: 'Bime',
    nivel: 'user',
    grupoMidia: 'bime',
    descricao: 'Conheça nossa plataforma de gestão empresarial. Simplifique o complexo e impulsione o seu sucesso.',
  },
];

export function getStoreAppByTag(
  tag: string | undefined,
): StoreAppProps | undefined {
  if (tag)
    return AnalyticsBIApps.find(
      (app) => tag.toLocaleLowerCase() === app.tag.toLocaleLowerCase(),
    )
  else return undefined
}
