import { Flex, Text, Textarea } from "@chakra-ui/react";
import { dataOrcamentoInterface } from "./interface";
export const Description = ({
  order,
  obsClient,
  setObsClient,
}: {
  order: dataOrcamentoInterface;
  obsClient: string;
  setObsClient: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const obsInternal = order.obsInternal;

  return (
    <Flex
      maxW={{ xl: "1024px", sm: "full" }}
      w="full"
      mx="auto"
      p={{ "2xl": "0 64px 10px 64px;", sm: "0 24px 10px 24px", base: "0 10px 10px 10px" }}
      mt="20px"
      borderBottom="1px solid #ccc"
    >
      <Flex flexDir="column" w="full">
        <Flex
          w="full"
          fontFamily={"inter"}
          mt="10px"
          gap="35px"
          flexDir={{ xl: "row", md: "row", sm: "column", base: "column" }}
        >
          <Flex flexDir={"column"} w="full">
            <Text fontSize={"13px"} color="#6747b3">
              Descrição
            </Text>
            <Flex
              bg="#f8f6fe"
              border="1px solid #f8f6fe"
              color="#606060"
              flexDir={"column"}
              borderRadius={"8px"}
              fontSize={"13px"}
            >
              {obsInternal?.map((item, index) => (
                <Text key={index} mt="3px">
                  {item}
                </Text>
              ))}
            </Flex>
          </Flex>

          <Flex flexDir={"column"} w="full" mb="20px">
            <Text fontSize={"13px"} color="#6747b3">
              Observação do cliente
            </Text>
            <Textarea
              onChange={(e) => setObsClient(e.target.value)}
              value={obsClient}
              isDisabled={order.status === "Aprovado"}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
