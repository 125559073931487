import {
  Box,
  Button,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import { UsuariosAtivosTable } from "./components/UsuariosAtivosTable";

import "keen-slider/keen-slider.min.css";
import { useEffect, useState } from "react";
import { useSession } from "src/contexts";
import { User } from "@/screens/Company-settings/Users/hooks";
import { apiInstance } from "src/services/api";
import { StoreAppProps } from "src/utilities/storeApps";
import { ModalAddUser } from "./components/ModalAddUser";
import { ModalTenhoInteresse } from "./components/ModalTenhoInteresse";
import { MediaAppsMarket } from "src/constants/hooks/iconsApps";
import "./../css/appDetails.css";
import "src/styles/scroll.css";
import { useGetApps } from "../services/useGetDataApps";
import { useGetStatusApp } from "../services/useGetStatusApp";

const bgColorActive = "rgba(16, 153, 114, 0.1)";
const bgColorInative = "rgba(102, 102, 102, 0.1)";
const borderColorInative = "#666666";
const borderColorActive = "#109972";

export interface AppDetailsProps extends StoreAppProps {
  isAdmin: boolean;
}

interface CurrentAppProps {
  appName: string;
  appStatus: boolean;
}

export const AppDetails = ({
  tag,
  appName,
  nivel,
  isAdmin,
  grupoMidia = "analyticspro",
}: AppDetailsProps) => {
  const { session } = useSession();
  const { dataApps } = useGetApps();
  const { dataAppsStatus } = useGetStatusApp();
  
  const [isOpenModalAddUser, setOpenModalAddUser] = useState<boolean>(false);
  const [reloadPage, setReloadPage] = useState<boolean>(false);
  const [isOpenModalTenhoInteresse, setOpenModalTenhoInteresse] =
    useState<boolean>(false);
  const [usersAuth, setUsersAuth] = useState<User[]>([]);
  const [keyApp, setKeyApp] = useState<string>("");
  const [currentApp, setCurrentApp] = useState<CurrentAppProps>({
    appName: '',
    appStatus: false,
  });
  async function getUsers() {
    await apiInstance(8006)
      .get(`/UserGestor/UsersByCompany/${session.company.document}`)
      .then((res) => {
        getUserAuthByTag(res.data, tag);
      });
  }

  function getUserAuthByTag(users: User[], tag: string) {
    const usersAuthByTag: User[] = users
      .filter(
        (user: any) =>
          user.aplicativos &&
          user.aplicativos.includes(tag) &&
          user.status === true
      )
      .map((user: any) => {
        const userAuthByTag: User = {
          userId: user.userId,
          name: user.name,
          email: user.email,
          status: user.status,
          perfilId: user.perfilId,
          perfilWs: user.perfilWs,
          aplicativos: user.aplicativos,
          perfilAplicativos: user.perfilAplicativos,
          indUserAtualiza: user.indUserAtualiza,
        };
        return userAuthByTag;
      });
    setUsersAuth(usersAuthByTag);
  }

  const openModalUser = () => {
    setOpenModalAddUser(true);
  };

  const clearkeyApp = (str: string) => {
    if (str.toLocaleLowerCase().includes('vendas')) {
      str = 'inteligenciadevendas';
    }

    const noSpaces = str.trim().toLowerCase();
    const noAccents = noSpaces.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const cleaned = noAccents.replace(/\s+/g, '');
    return cleaned;
  };

  const getStatusApp = (tag: string) => {
    if (dataAppsStatus) {
      const appSelected = dataAppsStatus.find(
        (app: { tag: string; indAtivar: string }) =>
          app.tag.toLowerCase() === tag.toLowerCase()
      );
      if (appSelected) {
        setCurrentApp({
          appName: appSelected?.indAtivar === 'S' ? appSelected?.nome : appSelected?.grupo,
          appStatus: appSelected?.indAtivar === 'S' ? true : false,
        });
      }
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadPage, isOpenModalAddUser]);

  useEffect(() => {
    setKeyApp(clearkeyApp(grupoMidia));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApps]);

  useEffect(() => {
    getStatusApp(tag);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAppsStatus]);

  const header = () => {
    return (
      <Flex w="100%">
        <Box className="boxHeaderInfo">
          <Image src={MediaAppsMarket[keyApp]?.icon} />
          <Box className="boxHeaderText">
            <Text fontSize={30} fontFamily={"Inter"} fontWeight={600}>
              {currentApp?.appName}
            </Text>
            <Button              
              bg={currentApp?.appStatus ? bgColorActive : bgColorInative}
              color={
                currentApp?.appStatus ? borderColorActive : borderColorInative
              }
              border={`1px solid ${
                currentApp?.appStatus ? borderColorActive : borderColorInative
              }`}
              size={"xs"}
              sx={{ cursor: "default", hover: "unset" }}
            >
              {currentApp?.appStatus ? "Ativo" : "Inativo"}
            </Button>
          </Box>
        </Box>
        {currentApp?.appStatus && (
          <Box className="boxHeaderbanner">
            <Image
              borderRadius="8px"
              src={MediaAppsMarket[keyApp]?.bannerAtivo}
              alt="workspace_img"
            />
          </Box>
        )}
      </Flex>
    );
  };

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        gap={4}
        overflowY={'auto'}
        className="custom-scrollbar"
      >
        {header()}
        <Box bg="white" w="100%" sx={{ borderRadius: '8px' }}>
          {currentApp?.appStatus ? (
            <Box className="infoApp">
              <Flex className="textVersionApp">
                <Flex sx={{ flexDirection: 'column' }}>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: (dataApps && dataApps[keyApp]?.version) || '-',
                    }}
                  />
                </Flex>
                {dataApps && dataApps[keyApp]?.buttonActive && (
                  <Box
                    as="a"
                    href={(dataApps && dataApps[keyApp]?.buttonActive) || '#'}
                    target={'_blank'}
                  >
                    <Button
                      colorScheme="blue"
                      borderRadius={'8px'}
                      size={'sm'}
                      variant="link"
                    >
                      Conteúdo completo
                    </Button>
                  </Box>
                )}
              </Flex>
              <Box
                className="textInfoApp"
                dangerouslySetInnerHTML={{
                  __html: (dataApps && dataApps[keyApp]?.textActive) || '-',
                }}
              />
            </Box>
          ) : (
            <Box>
              <Image
                src={MediaAppsMarket[keyApp]?.bannerInativo}
                borderRadius={'8px'}
              />
            </Box>
          )}
        </Box>
        {isAdmin && nivel === 'user' && (
          <Box bg="white" sx={{ borderRadius: '8px', marginBottom: '60px' }}>
            {currentApp?.appStatus ? (
              <Box>
                {tag !== 'MercadoLivre' && (
                  <UsuariosAtivosTable
                    app={{ appTag: tag, appName: appName }}
                    users={usersAuth}
                    reloadMainPage={() =>
                      setReloadPage((prevState) => !prevState)
                    }
                    openModalUser={openModalUser}
                  />
                )}
                {/* {tag === "MercadoLivre" && session.company.id === 191 && <IntegracaoMl />} */}
              </Box>
            ) : (
              <>
                <Box
                  className="textInfoApp"
                  dangerouslySetInnerHTML={{
                    __html: (dataApps && dataApps[keyApp]?.textInactive) || '-',
                  }}
                />
                {dataApps && dataApps[keyApp]?.buttonInactive && (
                  <Box className="actionApp">
                    <Box
                      as="a"
                      href={
                        (dataApps && dataApps[keyApp]?.buttonInactive) || '#'
                      }
                      target={'_blank'}
                    >
                      <Button
                        colorScheme="blue"
                        borderRadius={'8px'}
                        size={'sm'}
                      >
                        Quero contratar
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Flex>
      <ModalAddUser
        app={{ appTag: tag, appName: appName }}
        usersAuth={usersAuth}
        isOpen={isOpenModalAddUser}
        onOpen={() => setOpenModalAddUser(true)}
        onClose={() => {
          setOpenModalAddUser(false);
          setReloadPage((prevState) => !prevState);
        }}
      />
      <ModalTenhoInteresse
        isOpen={isOpenModalTenhoInteresse}
        onOpen={() => setOpenModalTenhoInteresse(true)}
        onClose={() => setOpenModalTenhoInteresse(false)}
        app_interesse={{ appTag: tag, appName: appName }}
      />
    </>
  );
};
