import React from "react";
import { Box } from "@chakra-ui/react";
import 'src/components/Layout/css/layout.css'

export const Instructions = () => {
  const srcImg =
    "https://imagens-design.londrisoftservices.com.br/workspace/assistentels/tutorial_de_instalacao.html";
  return (
    <Box id={'bx'} sx={{backgroundColor: "transparent", height: "100vh"}}  >
      <iframe
        src={srcImg}
        style={{ width: '100%', height: '100%', border: 'none', paddingBottom: '120px' }}
        title="Embedded HTML"
      />
    </Box>
  );
};


