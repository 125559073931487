import { Button, Flex } from '@chakra-ui/react'
import { UserRoundPlus } from 'lucide-react'

export interface ActionButtonProps {
  action: () => void
  labelButton: string
}
export const ActionButton = ({
  action,
  labelButton,
}: ActionButtonProps) => {
  return (
    <Flex gap={5} alignItems={'center'}>
      <Button
        colorScheme={'blue'}
        fontSize={'14px'}
        onClick={() => action()}
        borderRadius={'8px'}
        size={'sm'}
        leftIcon={
          labelButton === 'Adicionar' ? <UserRoundPlus /> : undefined
        }
      >
        {labelButton}
      </Button>
    </Flex>
  )
}
