import { useCallback, useEffect, useState } from "react";
import {
  IconsTools,
} from "src/constants/hooks/iconsApps";

export interface ObjToolsProps {
  name: string | undefined;
  description: string;
  link: string;
  img: string;
  isDownload: boolean;
}

const objTools: ObjToolsProps[] = [
  {
    name: IconsTools.anydesk.name,
    description:
      "Acesse qualquer dispositivo a qualquer momento e a partir de qualquer lugar, sempre de forma segura e rápida.",
    link: "https://anydesk.com/pt/downloads/windows",
    img: IconsTools.anydesk.url,
    isDownload: false,
  },
  {
    name: IconsTools.sevenzip.name,
    description:
      "Você pode usar 7-Zip em quaisquer computadores, incluindo computadores em organizações comerciais. Você não precisa registrar ou pagar pelo 7-Zip.",
    link: "https://www.7-zip.org/",
    img: IconsTools.sevenzip.url,
    isDownload: false,
  },
  {
    name: IconsTools.lightshot.name,
    description:
      "Este programa permite que você capture a tela diretamente de seu desktop.",
    link: "https://app.prntscr.com/pt-br/download.html",
    img: IconsTools.lightshot.url,
    isDownload: false,
  },
  {
    name: IconsTools.assistente.name,
    description:
      "Imprima de forma automática em seu computador, basta instalar nosso AssistenteLS.",
    link: "/store/instructions",
    img: IconsTools.assistente.url,
    isDownload: true,
  },
];

export function useToolsCard() {
  const [toolApps, setToolsApps] = useState<ObjToolsProps[]>();

  const returnTools = useCallback(() => {
    setToolsApps(objTools);
  }, []);

  useEffect(() => {
    returnTools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { toolApps };
}
