import { Button, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { User } from '@/screens/Company-settings/Users/hooks'
import { ModalRemoveUser } from '../../../ModalRemoveUser'
import { ReactComponent as UserMinusIcon } from 'src/assets/icons/user-minus-white.svg'

export interface DisableUserButtonProps {
  user: User
  appTag: string
  reloadMainPage: () => void
}
export const DisableUserButton = ({
  appTag,
  user,
  reloadMainPage,
}: DisableUserButtonProps) => {
  const [isOpenModalRemoveUser, setIsOpenModalRemoveUser] = useState(false)

  return (
    <>
      <Button
        leftIcon={<UserMinusIcon />}
        marginY={'5px'}
        size={'sm'}
        colorScheme={'blackAlpha'}
        onClick={() => setIsOpenModalRemoveUser(true)}
        borderRadius={'8px'}
      >
        <Text fontSize={'14px'}>Desativar</Text>
      </Button>
      <ModalRemoveUser
        appTag={appTag}
        user={user}
        isOpen={isOpenModalRemoveUser}
        onOpen={() => setIsOpenModalRemoveUser(true)}
        onClose={() => {
          reloadMainPage()
          setIsOpenModalRemoveUser(false)
        }}
      />
    </>
  )
}
